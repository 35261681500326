import React, { useState } from 'react';
import { Grid, Button, Paper, Typography, Modal, Box, Container } from '@mui/material';
import ImageGallery from 'react-image-gallery';
import emptyPic from '../../../assets/no-pic-loaded.PNG';
import './BasicInfo.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { FaCircle } from 'react-icons/fa';
import { IconContext } from 'react-icons';

function BasicInfo({ users, workstation }) {
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [currentGallery, setCurrentGallery] = useState('');

  const handleGalleryOpen = (galleryType) => {
    setCurrentGallery(galleryType);
    setGalleryOpen(true);
  };
  const handleGalleryClose = () => setGalleryOpen(false);

  const imagesDisconnect = workstation.pictures.filter((pic) => pic).map((pic) => ({
    original: pic, thumbnail: pic
  }));

  const imagesReconnect = Object.values(workstation.equipmentDamageAfter)
  .filter(damage => damage.image) // Ensure there's an image key
  .map(damage => ({
    original: damage.image,
    thumbnail: damage.image
  }));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto', maxWidth: '90vw', maxHeight: '90vh',
    bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, overflow: 'auto'
  };

  const UIDtoName = (userID) => users[userID] ? `${users[userID].firstName} ${users[userID].lastName}` : null;
  const disconnectedBy = UIDtoName(workstation.documentBy);
  const reconnectedBy = UIDtoName(workstation.reconnect?.completedBy);
  const qaBy = UIDtoName(workstation.qa?.completedBy);

  const renderField = (label, value) => value && (
    <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
      {`${label}: ${value}`}
    </Typography>
  );
  const renderBooleanField = (label, condition, trueLabel, falseLabel) => condition && (
    <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
      {label}: <strong>{condition ? trueLabel : falseLabel}</strong>
    </Typography>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} display="flex" flexDirection="column" alignItems="center">
        <img
          src={workstation.pictures.length > 0 && workstation.pictures[0] ? workstation.pictures[0] : emptyPic}
          style={{ maxHeight: 250, maxWidth: '100%' }}
          alt="Workstation preview"
        />
        <Box sx={{ display: 'flex', gap: 1, marginTop: 2 }}>
          {imagesDisconnect.length > 0 && (
            <Button variant="outlined" onClick={() => handleGalleryOpen('disconnect')}>View Disconnect</Button>
          )}
          {imagesReconnect.length > 0 && (
            <Button variant="outlined" onClick={() => handleGalleryOpen('reconnect')}>View Reconnect</Button>
          )}
        </Box>
        <Modal open={isGalleryOpen} onClose={handleGalleryClose}>
          <Box sx={modalStyle}>
            <ImageGallery
              items={currentGallery === 'disconnect' ? imagesDisconnect : imagesReconnect}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={currentGallery === 'disconnect' ? imagesDisconnect.length > 1 : imagesReconnect.length > 1}
            />
          </Box>
        </Modal>
        {Object.keys(workstation.issues).length > 0 && (
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>Issues</Typography>
              {Object.entries(workstation.issues).map(([id, issue]) => (
                <Grid container spacing={1} key={id} sx={{ marginTop: 1 }}>
                  <Grid item xs={12}>
                    <IconContext.Provider value={{ color: issue.resolvedBy ? 'green' : 'red' }}>
                      <FaCircle />
                    </IconContext.Provider>
                    {" " + issue.issue}
                  </Grid>
                </Grid>
              ))}
            </Paper>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5" textAlign="center" sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' }, fontWeight: 'bold' }}>{workstation.username}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>Origin</Typography>
              {renderField('Building', workstation.originBuilding)}
              {renderField('Floor', workstation.originFloor)}
              {renderField('Desk', workstation.origin)}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>Destination</Typography>
              {renderField('Building', workstation.destinationBuilding)}
              {renderField('Floor', workstation.destinationFloor)}
              {renderField('Desk', workstation.destination)}
            </Paper>
          </Grid>
          <Grid item xs={12}>
          {(disconnectedBy || reconnectedBy || qaBy) && (
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 1 }}>
              {renderField('Disconnected', disconnectedBy)}
              {renderField('Reconnected', reconnectedBy)}
              {renderField('QA', qaBy)}
            </Paper>
          )}
          </Grid>
        </Grid>
        <Container>
          <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
            {Object.entries(workstation.computers || {}).map(([key, computer], index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, fontWeight: 'bold' }}>
                    {`Computer #${index + 1}${computer.brand ? ` - ${computer.brand}` : ''}`}
                  </Typography>
                  {renderField('Serial Number', computer.serialNumber)}
                  {renderField('Type', computer.type)}
                  {renderField('Network Device', computer.networkDevice)}
                  {renderField('Network Device Port', computer.networkDevicePort)}
                </Paper>
              </Grid>
            ))}
            {Object.entries(workstation.monitors || {}).map(([key, monitor], index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, fontWeight: 'bold' }}>
                    {`Monitor #${index + 1}${monitor.brand ? ` - ${monitor.brand}` : ''}`}
                  </Typography>
                  {renderBooleanField('Orientation', monitor.vertical, 'Vertical', 'Horizontal')}
                  {renderBooleanField('Arm Mount', monitor.onArm, 'Yes', 'No')}
                  {renderBooleanField('Functional', monitor.notFunctional, 'No', 'Yes')}
                </Paper>
              </Grid>
            ))}
            {Object.entries(workstation.dockingStations || {}).map(([key, dockingStation], index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
                  <Typography variant="subtitle2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, fontWeight: 'bold' }}>
                    {`Docking Station #${index + 1}${dockingStation.brand ? ` - ${dockingStation.brand}` : ''}`}
                  </Typography>
                </Paper>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
                <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>Peripherals</Typography>
                {['Headsets', 'Keyboards', 'Mice', 'Phones', 'Speakers'].map((label) => (
                  <Typography key={label} sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
                    {`${label}: ${Object.keys(workstation.peripherals?.[label.toLowerCase()] || {}).length}`}
                  </Typography>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default BasicInfo;
