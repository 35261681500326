import React, { useState } from 'react';
import { Accordion, AccordionSummary, Typography, Grid, Modal, Box, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircleIcon from '@mui/icons-material/Circle';
import BasicInfo from './BasicInfo';

function Workstations({ users = {}, workstations }) {
  const [openModal, setOpenModal] = useState(false);
  const [currentWorkstation, setCurrentWorkstation] = useState(null);

  const handleOpenModal = (workstation) => {
    setCurrentWorkstation(workstation);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentWorkstation(null);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto'
  };

  const chooseColor = (workstation) => {
    if (workstation.qa && workstation.qa.completedBy) {
      if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) {
        return 'greenWithRedBorder';
      }
      return 'green';
    }
    if (workstation.disconnectOnly) return 'green';
    if (workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy)) return 'red';
    return workstation.reconnect?.completedBy ? 'yellow' : 'white';
  };

  const getColorTooltip = (color, workstation) => {
    if (workstation.disconnectOnly) return "Disconnect Only";
    switch (color) {
      case 'green':
        return "QA'd";
      case 'yellow':
        return "Reconnected";
      case 'red':
        return "Issue";
      case 'greenWithRedBorder':
        return "QA'd with Issue";
      case 'white':
      default:
        return "Disconnected";
    }
  };

  const getCircleStyle = (workstation) => {
    const color = chooseColor(workstation);
    const hasUnresolvedClientIssues = workstation.issues && Object.values(workstation.issues).some((issue) => issue.clientIssue && !issue.resolvedBy);

    if (color === 'white') {
      return {
        color: 'white',
        borderRadius: '50%',
        border: '1px solid grey',
      };
    }
    return {
      color: color === 'greenWithRedBorder' ? 'green' : color,
      borderRadius: '50%',
      border: hasUnresolvedClientIssues ? '1px solid red' : 'none',
      backgroundColor: hasUnresolvedClientIssues ? 'red' : 'transparent',
      padding: hasUnresolvedClientIssues ? '0' : 'none',
      boxSizing: 'border-box',
    };
  };

  if (!workstations) return null;

  return (
    <>
      {Object.entries(workstations).map(([id, workstation]) => (
        <Accordion key={id} expanded={false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${id}-content`}
            id={`panel${id}-header`}
            onClick={() => handleOpenModal(workstation)}
          >
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={4}><Typography variant="body1">{workstation.username}</Typography></Grid>
              <Grid item xs={3.7}><Typography variant="body1">{workstation.destination}</Typography></Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  {Object.values(workstation.issues || {}).filter(issue => issue.clientIssue).length}
                </Typography>
              </Grid>
              <Grid item xs={0}>
                <Tooltip title={getColorTooltip(chooseColor(workstation), workstation)}>
                  <CircleIcon sx={getCircleStyle(workstation)} />
                </Tooltip>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      ))}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          {currentWorkstation && <BasicInfo users={users} workstation={currentWorkstation} />}
        </Box>
      </Modal>
    </>
  );
}

export default Workstations;
