import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux'; // Add this
import { firestoreConnect } from 'react-redux-firebase'; // Add this
import HamburgerMenu from './HamburgerMenu';
import ProjectAnalyticsThin from '../project/ProjectAnalyticsThin';
import Project from '../project/Project';
import { Container, Box } from '@mui/material';
import SelectClientNotice from './SelectClientNotice';
import Title from '../project/Title';

const Dashboard = ({ auth, profile, configuration }) => {
  const [workstations, setWorkstations] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [customTitle, setCustomTitle] = useState(null);
  const [url, setURL] = useState(null);
  const [refLocation, setRefLocation] = useState(null);

  let content;

  if (!customTitle) {
    content = <SelectClientNotice />
  } else if (!workstations.length) {
    content = <Title title={customTitle} />;
  } else if (selectedProject) {
    content = <Project title={customTitle} workstations={workstations} auth={auth} url={url} refLocation={refLocation} />;
  } else {
    content = (
      <>
        <Title title={customTitle} />
        <ProjectAnalyticsThin workstations={workstations} />
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column', // For small screens
          sm: 'row', // For medium and larger screens
        },
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'relative', zIndex: 1, height: { xs: 'auto', sm: '100%' } }}>
        <HamburgerMenu
          auth={auth}
          profile={profile}
          setWorkstations={setWorkstations}
          setSelectedProject={setSelectedProject}
          setCustomTitle={setCustomTitle}
          setURL={setURL}
          setRefLocation={setRefLocation}
          configuration={configuration}
        />
      </Box>
      <Container maxWidth="md">
        {content}
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  configuration: state.firestore.data.configuration,
});

// export default connect(mapStateToProps, null)(Dashboard);


export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
      return [
          {
              collection: 'projects',
              doc: 'configuration',
              storeAs: 'configuration'
          },
      ];
  })
)(Dashboard);
