import React, { useState, useEffect } from 'react';
import { Sling as Hamburger } from 'hamburger-react';
import { useFirestore } from 'react-redux-firebase';
import { Typography, MenuItem, Select, FormControl, InputLabel, Paper, IconButton } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import SignOut from '../auth/SignOut';
import moment from 'moment';

const HamburgerMenu = ({ auth, profile, setWorkstations, setSelectedProject, setCustomTitle, setURL, configuration, setRefLocation }) => {
  const firestore = useFirestore();

  const [isOpen, setOpen] = useState(true);
  const [userData, setUserData] = useState({});
  const [groupedProjects, setGroupedProjects] = useState({});
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [clientNames, setClientNames] = useState({});
  const [activeStatsIcon, setActiveStatsIcon] = useState(null);

  const monthOrder = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const styles = {
    container: {
      position: 'absolute', 
      left: '50px', 
      width: '300px', 
      backgroundColor: 'white', 
      border: '1px solid #ccc', 
      borderRadius: '8px', 
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
      zIndex: 1, 
      padding: '5px'
    }
  };

  useEffect(() => {
    if (isOpen && auth.uid && profile) {
      const loadClients = async () => {
        try {
          if (profile.role === 'admin' || profile.role === 'supervisor') {
            const clientSnapshot = await firestore.collection('clients').get();
            const allClients = clientSnapshot.docs.map(clientDoc => ({
              id: clientDoc.id,
              name: clientDoc.data().name,
            }));

            const sortedClients = allClients.sort((a, b) => a.name.localeCompare(b.name));
            const allProjects = await Promise.all(
              sortedClients.map(async (client) => {
                const projectsSnapshot = await firestore.collection('clients').doc(client.id).collection('projects').get();
                const projects = projectsSnapshot.docs.map(projectDoc => ({
                  ...projectDoc.data(),
                  clientID: client.id,
                  date: projectDoc.data().date.toDate(),
                  id: projectDoc.id
                }));
                return { clientID: client.id, projects };
              })
            );
            const grouped = groupProjectsByYearAndMonth(allProjects);
            setClientNames(sortedClients.reduce((acc, client) => ({ ...acc, [client.id]: client.name }), {}));
            setGroupedProjects(grouped);
          } else {
            const userDoc = await firestore.collection('users').doc(auth.uid).get();
            const data = userDoc.data();
            const clientScope = data.clientScope;

            if (!Array.isArray(clientScope) || clientScope.length === 0) {
              console.error('No clients available in clientScope');
              return;
            }

            const clientPromises = clientScope.map(async (clientID) => {
              const clientDoc = await firestore.collection('clients').doc(clientID).get();
              if (!clientDoc.exists) {
                console.error(`Client with ID ${clientID} does not exist`);
                return null;
              }

              const clientData = clientDoc.data();
              setClientNames((prev) => ({ ...prev, [clientID]: clientData.name }));

              const projectSnapshot = await firestore.collection('clients').doc(clientID).collection('projects').get();
              const projects = projectSnapshot.docs.map((projectDoc) => ({
                ...projectDoc.data(),
                clientID,
                date: projectDoc.data().date.toDate(),
                id: projectDoc.id
              }));

              return { clientID, projects };
            });

            const clientsProjects = await Promise.all(clientPromises);
            const grouped = groupProjectsByYearAndMonth(clientsProjects);
            setGroupedProjects(grouped);
          }
        } catch (error) {
          console.error('Error fetching clients and projects:', error);
        }
      };

      loadClients();
    }
  }, [isOpen, firestore, auth.uid, profile]);

  const groupProjectsByYearAndMonth = (clientsProjects) => {
    return clientsProjects.reduce((acc, { clientID, projects }) => {
      if (clientID) {
        acc[clientID] = projects.reduce((yearAcc, projectData) => {
          const year = projectData.date.getFullYear();
          const month = projectData.date.toLocaleString('default', { month: 'long' });
  
          if (!yearAcc[year]) {
            yearAcc[year] = {};
          }
  
          if (!yearAcc[year][month]) {
            yearAcc[year][month] = [];
          }
  
          yearAcc[year][month].push(projectData);
  
          // Sort the projects by date after adding each one to the month's array
          yearAcc[year][month].sort((a, b) => a.date - b.date);
          
          return yearAcc;
        }, {});
      }
  
      acc['all'] = acc['all'] || {}; 
      projects.forEach((projectData) => {
        const year = projectData.date.getFullYear();
        const month = projectData.date.toLocaleString('default', { month: 'long' });
  
        if (!acc['all'][year]) {
          acc['all'][year] = {};
        }
  
        if (!acc['all'][year][month]) {
          acc['all'][year][month] = [];
        }
  
        acc['all'][year][month].push(projectData);
  
        // Sort the projects by date within the 'all' group as well
        acc['all'][year][month].sort((a, b) => a.date - b.date);
      });
  
      return acc;
    }, {});
  };
  

  const handleClientClick = (clientID) => {
    setSelectedClient(clientID);
    setCustomTitle(clientID === 'all' ? 'All Companies' : clientNames[clientID]);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedProjectId(null);
    setSelectedProject(null);
    setWorkstations([]);
    setActiveStatsIcon(null);
  };

  const handleYearClick = (year) => {
    setSelectedYear(selectedYear === year ? null : year);
    setSelectedMonth(null);
    setSelectedProjectId(null);
  };

  const handleMonthClick = (year, month) => {
    setSelectedYear(year);
    setSelectedMonth(selectedMonth === month ? null : month);
    setSelectedProjectId(null);
  };

  const handleProjectClick = (year, month, projectId, clientID) => {
    setSelectedYear(year);
    setSelectedMonth(month);
    setActiveStatsIcon(null);
    setSelectedProjectId(selectedProjectId === projectId ? null : projectId);

    if (selectedProjectId !== projectId) {
      const projectRef = firestore.collection('clients').doc(clientID).collection('projects').doc(projectId);
      projectRef.get()
        .then((projectDoc) => {
          if (projectDoc.exists) {
            const projectData = projectDoc.data();
            setSelectedProject(projectData);

            const date = moment.unix(projectData.date.seconds).format('MMMM D, YYYY');
            setCustomTitle(`${clientNames[clientID]} : ${date}`);

            const refLocation = projectData.refLocation.path;

            const config = configuration || { scheme: 'https', host: 'murphymckay.online' };
            const projectURL = `${config.scheme}://${config.host}/${refLocation}`;
            setURL(projectURL);
            setRefLocation(refLocation);

            if (refLocation) {
              const workstationsRef = firestore.doc(refLocation).collection('workstations');
              return workstationsRef.get();
            } else {
              console.log('No refLocation found in project document.');
              throw new Error('No refLocation found.');
            }
          } else {
            console.log('No such project!');
            throw new Error('No such project!');
          }
        })
        .then((workstationsSnapshot) => {
          const workstationsData = workstationsSnapshot.docs.map(doc => doc.data());
          setWorkstations(workstationsData);
        })
        .catch((error) => {
          console.error('Error fetching project or workstations:', error);
        });
    } else {
      setSelectedProject(null);
      setWorkstations([]);
      setCustomTitle(`${clientNames[clientID]}`);
    }
  };

  const handleYearStatsClick = async (year, e) => {
    e.stopPropagation();
    
    setActiveStatsIcon(activeStatsIcon === `year-${year}` ? null : `year-${year}`);
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedProjectId(null);
    setSelectedProject(null);

    if (selectedClient === 'all') {
        setCustomTitle(`All Companies: ${year}`);
    } else {
        setCustomTitle(`${clientNames[selectedClient]}: ${year}`);
    }

    if (groupedProjects[selectedClient] && groupedProjects[selectedClient][year]) {
      const months = groupedProjects[selectedClient][year];
      const workstationsPromises = [];

      Object.entries(months).forEach(([month, projects]) => {
        projects.forEach((projectData) => {
          const refLocation = projectData.refLocation.path;

          if (refLocation) {
            const workstationsRef = firestore.doc(refLocation).collection('workstations');
            workstationsPromises.push(workstationsRef.get());
          }
        });
      });

      try {
        const workstationsSnapshots = await Promise.all(workstationsPromises);
        const allWorkstations = workstationsSnapshots.flatMap(snapshot => 
          snapshot.docs.map(doc => doc.data())
        );

        setWorkstations(allWorkstations);
      } catch (error) {
        console.error('Error fetching workstations for the year:', error);
      }
    } else {
      console.log('No projects found for this year.');
    }
  };

  const handleMonthStatsClick = async (year, month, e) => {
    e.stopPropagation();
    setActiveStatsIcon(activeStatsIcon === `month-${year}-${month}` ? null : `month-${year}-${month}`);
    setSelectedMonth(null);
    setSelectedProjectId(null);
    setSelectedProject(null);
    setWorkstations([]);

    if (selectedClient === 'all') {
        setCustomTitle(`All Companies: ${month} ${year}`);
    } else {
        setCustomTitle(`${clientNames[selectedClient]}: ${month} ${year}`);
    }

    if (groupedProjects[selectedClient] && groupedProjects[selectedClient][year] && groupedProjects[selectedClient][year][month]) {
      const projects = groupedProjects[selectedClient][year][month];
      const workstationsPromises = [];

      projects.forEach((projectData) => {
        const refLocation = projectData.refLocation.path;

        if (refLocation) {
          const workstationsRef = firestore.doc(refLocation).collection('workstations');
          workstationsPromises.push(workstationsRef.get());
        }
      });

      try {
        const workstationsSnapshots = await Promise.all(workstationsPromises);
        const allWorkstations = workstationsSnapshots.flatMap(snapshot =>
          snapshot.docs.map(doc => doc.data())
        );

        setWorkstations(allWorkstations);
      } catch (error) {
        console.error('Error fetching workstations for the month:', error);
      }
    } else {
      console.log('No projects found for this month.');
    }
  };

  function formatDayWithSuffix(day) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }

  return (
    <div>
      <Hamburger toggled={isOpen} toggle={setOpen} />
      {isOpen && (
        <div style={styles.container}>
          {Object.keys(clientNames).length > 0 && (
            <FormControl fullWidth>
              <InputLabel>Select Company</InputLabel>
              <Select
                value={selectedClient || ''}
                onChange={(e) => handleClientClick(e.target.value)}
                label="Select Company"
              >
                {Object.keys(clientNames).length > 1 && (
                  <MenuItem value="all">All Companies</MenuItem>
                )}
                {Object.keys(clientNames || {}).map((clientID) => (
                  <MenuItem key={clientID} value={clientID}>
                    {clientNames[clientID]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedClient && groupedProjects[selectedClient] && (
            <ul style={{ padding: '0', marginBottom: '20px', listStyleType: 'none', textAlign: 'center' }}>
              {Object.entries(groupedProjects[selectedClient])
                .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA))
                .map(([year, months]) => (
                  <li key={year} style={{ marginBottom: '10px', position: 'relative', marginLeft: '0', marginRight: '0' }}>
                    <Paper variant="outlined" sx={{ padding: 1, border: '1px solid #ccc', borderRadius: '8px', width: '90%', margin: '0 auto' }}>
                      <Paper
                        elevation={3}
                        sx={{
                          cursor: 'pointer',
                          textAlign: 'center',
                          margin: '0 auto',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '8px 16px',
                          position: 'relative',
                        }}
                        onClick={() => handleYearClick(year)}
                      >
                        <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                          {year}
                        </Typography>
                        <IconButton
                          sx={{
                            position: 'absolute',
                            right: 8,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: activeStatsIcon === `year-${year}` ? '#1976d2' : 'black',
                          }}
                          onClick={(e) => handleYearStatsClick(year, e)}
                        >
                          <BarChartIcon />
                        </IconButton>
                      </Paper>
                      {selectedYear === year && (
                        <ul style={{ padding: '0', marginTop: '10px', listStyleType: 'none', textAlign: 'center' }}>
                          {Object.entries(months)
                            .sort((a, b) => monthOrder.indexOf(a[0]) - monthOrder.indexOf(b[0]))
                            .map(([month, projects]) => (
                              <li key={month} style={{ marginBottom: '10px', marginLeft: '0', marginRight: '0' }}>
                                <Paper
                                  elevation={3}
                                  sx={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    margin: '0 auto',
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '8px 16px',
                                    position: 'relative',
                                  }}
                                  onClick={() => handleMonthClick(year, month)}
                                >
                                  <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                                    {month}
                                  </Typography>
                                  <IconButton
                                    sx={{
                                      position: 'absolute',
                                      right: 8,
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      color: activeStatsIcon === `month-${year}-${month}` ? '#1976d2' : 'black',
                                    }}
                                    onClick={(e) => handleMonthStatsClick(year, month, e)}
                                  >
                                    <BarChartIcon />
                                  </IconButton>
                                </Paper>
                                {selectedMonth === month && (
                                  <ul style={{ padding: '0', marginTop: '10px', listStyleType: 'none', textAlign: 'center' }}>
                                    {projects
                                      .sort((a, b) => {
                                        const dateA = a.date instanceof Date ? a.date : a.date.toDate();
                                        const dateB = b.date instanceof Date ? a.date : a.date.toDate();
                                        return dateA - dateB;
                                      })
                                      .map((projectData, index) => {
                                        const date = projectData.date instanceof Date ? projectData.date : projectData.date.toDate();
                                        const dayWithSuffix = formatDayWithSuffix(date.getDate());
                                        return (
                                          <li key={index} style={{ marginBottom: '10px', marginLeft: '0', marginRight: '0' }}>
                                            <Paper
                                              elevation={3}
                                              sx={{
                                                color: selectedProjectId === projectData.id ? 'white' : 'black',
                                                backgroundColor: selectedProjectId === projectData.id ? '#1976d2' : '#FFF',
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                                margin: '0 auto',
                                                width: '100%',
                                              }}
                                              onClick={() => handleProjectClick(year, month, projectData.id, projectData.clientID)}
                                            >
                                              <Typography variant="body1">
                                                {`${dayWithSuffix}`}
                                              </Typography>
                                            </Paper>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                )}
                              </li>
                            ))}
                        </ul>
                      )}
                    </Paper>
                  </li>
                ))}
            </ul>
          )}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <SignOut auth={auth} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
