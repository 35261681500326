import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from '@mui/material';
import { Box, Typography } from '@mui/material';

import RoleRedirect from './components/home/RoleRedirect';
import Home from './components/home/Home';
import Dashboard from './components/home/Dashboard';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import ProjectParams from './components/project/ProjectParams';
import CreateProject from './components/project/CreateProject';

const theme = createTheme({
  typography: {
    allVariants: {
      textTransform: 'none',
    },
   }
});

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: "#555"
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path='/*'                              element={<RoleRedirect />} />
          <Route path='/dashboard'                      element={<Dashboard />} />
          <Route path='/signin/*'                       element={<SignIn />} />
          <Route path='/signup/*'                       element={<SignUp />} />
          <Route path='/projects/:year/:month/:id'      element={<ProjectParams />} />
          <Route path='/create'                         element={<CreateProject />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
