import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const createProject = (project) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        // Check if startTime is valid
        const isValidStartTime = moment(project.startTime, "YYYY-MM-DDTHH:mm", true).isValid();
        // Use current date as fallback if startTime is invalid
        const dateTime = isValidStartTime ? project.startTime : new Date().toISOString();

        // Format the date using the validated or fallback dateTime
        const year = moment(dateTime, "YYYY-MM-DDTHH:mm").format('YYYY');
        const month = moment(dateTime, "YYYY-MM-DDTHH:mm").format('MMMM');

        // Reference to the new document
        var fileRef = firestore.collection('projects')
                               .doc(year)
                               .collection(month)
                               .doc(uuidv4());

        fileRef.set({
            // ...project, 
            client: "779258bf-5561-45a0-97ec-66046201c63b",
            date: new Date(),
            itemType: 0,
            linkedProjects: [],
            name: project.title,
            public: false
        }).then(() => {
            dispatch({ type: 'CREATE_PROJECT', project });
        }).catch((err) => {
            dispatch({ type: 'CREATE_PROJECT_ERROR', err });
        })
    }
};
